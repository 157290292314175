import { createVuetify } from "vuetify";
import "vuetify/styles";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

export default createVuetify({
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#2F69C0",
          background: "#FFFFFF",
          sidebar: "2F2E2E",
          topbar: "FF1313",
          switch: "#4588ed",
          card: "#F5F5F5",
          card2: "#F5F5F5",
          cardtext: "#2F69C0",
        },
      },
      dark: {
        colors: {
          primary: "#2F69C0",
          background: "#1E2E3D",
          sidebar: "2F2E2E",
          topbar: "FF1313",
          switch: "#1E2E3D",
          card: "#273444",
          card2: "#0e1012",
          cardtext: "#B1B1B1",
        },
      },
    },
  },
  components: {
    ...components,
  },
  directives,
  icons: {
    iconfont: "mdi", // 'mdi' for Material Design Icons
  },
});
